<template>
  <div class="main" v-if="!isLoading" ref="element">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(processFormRegister)">
        <div>
          <div class="columns">
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <router-link :to="{ name: `interview` }">
                      <h3 class="is-size-3">INTERVIEW SCHEDULE</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-3 breadcrumb-item"
                      v-if="$route.name == 'createInterviewSchedule'"
                    >
                      CREATE
                    </h3>
                    <h3 class="is-size-3 breadcrumb-item" v-else>EDIT</h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Case Study</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`caseStudy`"
                              name="caseStudy"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field
                                :type="errors.length > 0 ? 'is-danger' : null"
                              >
                                <b-taginput
                                  v-model="interview.caseStudy"
                                  :data="caseStudyTags"
                                  :open-on-focus="true"
                                  autocomplete
                                  placeholder="Type to search"
                                  @typing="getCaseStudy"
                                >
                                  <template slot="empty"
                                    >There are no items</template
                                  >
                                </b-taginput>
                              </b-field>
                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Schedule</b>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              name="date"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-datetimepicker
                                rounded
                                placeholder="Click to select..."
                                icon="calendar"
                                :min-datetime="minDate"
                                v-model="interview.interviewDate"
                              />
                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>User psikolog</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              :vid="`userPsikolog`"
                              name="userPsikolog"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field
                                :type="errors.length > 0 ? 'is-danger' : null"
                              >
                                <b-taginput
                                  v-model="interview.user"
                                  :data="userTags"
                                  :open-on-focus="true"
                                  autocomplete
                                  placeholder="Type to search"
                                  @typing="getUser"
                                >
                                  <template slot="empty"
                                    >There are no items</template
                                  >
                                </b-taginput>
                              </b-field>
                              <span class="required">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns is-multiline content">
                          <div
                            class="role-play content column is-12"
                            v-for="(role, index) in interview.rolePlay"
                            :key="index"
                          >
                            <div class="columns is-multiline">
                              <div class="column is-2">
                                <b>Variant {{ index + 1 }}</b>
                              </div>
                              <div class="column is-10">
                                <section class="hero is-light">
                                  <div class="hero-head">
                                    <b-field grouped position="is-right"
                                      ><b-button
                                        v-if="index != 0"
                                        type="is-text"
                                        icon-left="times"
                                        @click="deleteRolePlay(index)"
                                      ></b-button
                                    ></b-field>
                                  </div>
                                  <div class="hero-body">
                                    <div class="columns">
                                      <div class="column is-2">
                                        Role Play
                                        <b-tooltip
                                          type="is-danger"
                                          label="This field is required"
                                        >
                                          <span class="required">*</span>
                                        </b-tooltip>
                                      </div>
                                      <div class="column is-10">
                                        <ValidationProvider
                                          name="rules"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <b-input
                                            v-model="role.role"
                                          ></b-input>
                                          <span class="required">
                                            {{ errors[0] }}</span
                                          >
                                        </ValidationProvider>
                                      </div>
                                    </div>
                                    <div class="columns">
                                      <div class="column is-2">
                                        Email List
                                        <b-tooltip
                                          type="is-danger"
                                          label="This field is required"
                                        >
                                          <span class="required">*</span>
                                        </b-tooltip>
                                      </div>
                                      <div class="column is-10">
                                        <ValidationProvider
                                          name="rules"
                                          rules="required"
                                          v-slot="{ errors }"
                                        >
                                          <b-taginput
                                            v-model="role.emailList"
                                            :data="role.emailList"
                                            autocomplete
                                            :allow-new="true"
                                            icon="tag"
                                            placeholder="Add email"
                                          >
                                          </b-taginput>
                                          <span class="required">
                                            {{ errors[0] }}</span
                                          >
                                        </ValidationProvider>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-field>
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Variant {{ interview.rolePlay.length + 1 }}</b>
                          </div>
                          <div class="column is-10">
                            <b-button
                              type="is-hcc"
                              icon-left="plus"
                              @click="addRole()"
                              expanded
                              >Add Role & Email</b-button
                            >
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <b-field grouped position="is-centered">
                        <div class="columns content">
                          <div class="column is-2">
                            <b>Zoom Url</b>
                            <b-tooltip
                              type="is-danger"
                              label="This field is required"
                            >
                              <span class="required">*</span>
                            </b-tooltip>
                          </div>
                          <div class="column is-10">
                            <ValidationProvider
                              name="rules"
                              :rules="`required`"
                              v-slot="{ errors }"
                            >
                              <ckeditor
                                v-model="interview.zoomUrl"
                                :config="editorConfig"
                              />
                              <span class="required"> {{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    const today = new Date();
    return {
      isLoading: true,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      interview: {
        rolePlay: [
          {
            role: "",
            emailList: []
          }
        ]
      },
      userTags: [],
      caseStudyTags: [],
      editorConfig: {
        extraAllowedContent: "div[class]",
        allowedContent: true,
        extraPlugins: "justify,font"
      }
    };
  },
  created() {
    if (this.$route.name == "editInterviewSchedule") {
      this.fetchData();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async deleteRolePlay(index) {
      this.interview.rolePlay.splice(index, 1);
    },
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getInterviewSchedule",
        this.$route.params.interviewId
      );
      this.interview = response.data;
      this.interview.caseStudy = [this.interview.caseStudy];
      this.interview.zoomUrl = response.data.linkZoom;
      this.interview.interviewDate = new Date(response.data.interviewSchedule);
      this.isLoading = false;
    },
    addRole() {
      var rolePlay = {
        role: "",
        email: []
      };
      this.interview.rolePlay.push(rolePlay);
    },
    getCaseStudy(search) {
      this.caseStudyTags = [];
      var content = [];
      content.search = search;
      if (this.interview.caseStudy == undefined) {
        content.caseStudyList = [];
      } else {
        content.caseStudyList = this.interview.caseStudy;
      }
      this.$store.dispatch("searchCaseStudyLGD", content).then(data => {
        if (!data) {
          this.caseStudyTags = [];
        } else {
          this.caseStudyTags = data.caseStudy;
        }
      });
    },
    getUser(search) {
      this.userTags = [];
      var content = [];
      content.search = search;
      if (this.interview.user == undefined) {
        content.userList = [];
      } else {
        content.userList = this.interview.user;
      }
      this.$store.dispatch("searchUserLGD", content).then(data => {
        if (!data) {
          this.userTags = [];
        } else {
          this.userTags = data.user;
        }
      });
    },
    isEmail(email) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true;
      }
      return false;
    },
    processFormRegister() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      if (this.$route.name == "createInterviewSchedule") {
        this.$store
          .dispatch("storeInterviewSchedule", this.interview)
          .then(response => {
            if (response.data.status == 406) {
              loadingComponent.close();
              this.danger(response.data.message);
            } else {
              loadingComponent.close();
              this.success("Successfully save psikotest introduction");
              this.$router.push({ name: "interview" });
            }
          })
          .catch(() => {
            this.danger("Failed to create data");
          });
      } else {
        this.$store
          .dispatch("updateInterviewSchedule", this.interview)
          .then(response => {
            if (response.data.status == 406) {
              loadingComponent.close();
              this.danger(response.data.message);
            } else {
              loadingComponent.close();
              this.success("Successfully save psikotest introduction");
              this.$router.push({ name: "interview" });
            }
          })
          .catch(() => {
            this.danger("Failed to create data");
          });
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>
<style>
.delete-button {
  padding-left: 0 !important;
}
.role-play {
  width: 100% !important;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
}
.hero-body {
  padding-top: 1% !important;
  padding-bottom: 2% !important;
}
.content {
  width: 90%;
}
</style>
