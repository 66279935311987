var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{ref:"element",staticClass:"main"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processFormRegister)}}},[_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-10"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: "interview" }}},[_c('h3',{staticClass:"is-size-3"},[_vm._v("INTERVIEW SCHEDULE")])])],1),_c('li',[(_vm.$route.name == 'createInterviewSchedule')?_c('h3',{staticClass:"is-size-3 breadcrumb-item"},[_vm._v(" CREATE ")]):_c('h3',{staticClass:"is-size-3 breadcrumb-item"},[_vm._v("EDIT")])])])])]),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{attrs:{"type":"is-hcc","native-type":"submit"}},[_vm._v("Save")])],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns content"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Case Study")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"caseStudy","name":"caseStudy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.caseStudyTags,"open-on-focus":true,"autocomplete":"","placeholder":"Type to search"},on:{"typing":_vm.getCaseStudy},model:{value:(_vm.interview.caseStudy),callback:function ($$v) {_vm.$set(_vm.interview, "caseStudy", $$v)},expression:"interview.caseStudy"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns content"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Schedule")])]),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datetimepicker',{attrs:{"rounded":"","placeholder":"Click to select...","icon":"calendar","min-datetime":_vm.minDate},model:{value:(_vm.interview.interviewDate),callback:function ($$v) {_vm.$set(_vm.interview, "interviewDate", $$v)},expression:"interview.interviewDate"}}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns content"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("User psikolog")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"userPsikolog","name":"userPsikolog","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.userTags,"open-on-focus":true,"autocomplete":"","placeholder":"Type to search"},on:{"typing":_vm.getUser},model:{value:(_vm.interview.user),callback:function ($$v) {_vm.$set(_vm.interview, "user", $$v)},expression:"interview.user"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns is-multiline content"},_vm._l((_vm.interview.rolePlay),function(role,index){return _c('div',{key:index,staticClass:"role-play content column is-12"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Variant "+_vm._s(index + 1))])]),_c('div',{staticClass:"column is-10"},[_c('section',{staticClass:"hero is-light"},[_c('div',{staticClass:"hero-head"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[(index != 0)?_c('b-button',{attrs:{"type":"is-text","icon-left":"times"},on:{"click":function($event){return _vm.deleteRolePlay(index)}}}):_vm._e()],1)],1),_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_vm._v(" Role Play "),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"rules","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(role.role),callback:function ($$v) {_vm.$set(role, "role", $$v)},expression:"role.role"}}),_c('span',{staticClass:"required"},[_vm._v(" "+_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_vm._v(" Email List "),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"rules","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-taginput',{attrs:{"data":role.emailList,"autocomplete":"","allow-new":true,"icon":"tag","placeholder":"Add email"},model:{value:(role.emailList),callback:function ($$v) {_vm.$set(role, "emailList", $$v)},expression:"role.emailList"}}),_c('span',{staticClass:"required"},[_vm._v(" "+_vm._s(errors[0]))])]}}],null,true)})],1)])])])])])])}),0)]),_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns content"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Variant "+_vm._s(_vm.interview.rolePlay.length + 1))])]),_c('div',{staticClass:"column is-10"},[_c('b-button',{attrs:{"type":"is-hcc","icon-left":"plus","expanded":""},on:{"click":function($event){return _vm.addRole()}}},[_vm._v("Add Role & Email")])],1)])])],1)])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"columns content"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Zoom Url")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"rules","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.interview.zoomUrl),callback:function ($$v) {_vm.$set(_vm.interview, "zoomUrl", $$v)},expression:"interview.zoomUrl"}}),_c('span',{staticClass:"required"},[_vm._v(" "+_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)])])])])])])])]}}],null,false,2800213251)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }